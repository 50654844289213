import clsx from 'clsx';
import styles from './Calendar.module.scss';

export type AppointmentStatus =
  | 'existing_booking'
  | 'unexpected_error'
  | 'expired_invite'
  | 'success'
  | 'loading'
  | 'loaded'
  | 'ended'
  | 'future';

export type CalendarProps = {
  target: string;
  status: AppointmentStatus;
  hiddenSlots?: boolean;
};

export const Calendar = ({
  target,
  status,
  hiddenSlots,
}: CalendarProps): JSX.Element => {
  return (
    <div
      id={target}
      className={clsx(styles.datepicker, hiddenSlots && styles.hiddenSlots)}
      style={{ display: status === 'loaded' ? 'block' : 'none' }}
    />
  );
};
