import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import React, { useRef, useState } from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { backgroundStyleClass } from '@quno/patient-journey/src/theme/backgroundStyleClass';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import { hideConversionBarUntilScroll } from '@quno/patient-journey/src/utils/hideConversionBarUntilScroll';
import { textColorClass } from '@quno/patient-journey/src/theme/textColorClass';
import { getOpacity } from '@quno/patient-journey/src/utils/opacity';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { Cta } from '@quno/patient-journey/src/components/Cta/Cta';
import ExternalFunnel from '@quno/patient-journey/src/components/shared/ExternalFunnel/ExternalFunnel';
import ExternalFunnelOnboardingPreview from '@quno/patient-journey/src/components/shared/ExternalFunnel/ExternalFunnelOnboardingPreview';
import Image from 'next/image';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import styles from './HeroFunnel.module.scss';
import type { Package } from '../../contentful/patient-journey/blocks/BlockCard';
import type { CtaFragment } from '../../contentful/generated/graphql-patient-journey';
import type { HeroProps } from '@quno/patient-journey/src/components/Hero/hero.config';

export type HeroFunnelProps = HeroProps & {
  funnelId: string;
  ctas?: (CtaFragment & { funnelId?: string })[];
  locale?: string;
  isPreview?: boolean;
  isFirstBlock?: boolean;
};

export const HeroFunnel = ({
  cid,
  funnelId,
  text,
  image,
  centerContent,
  flipContent,
  darkModeEnabled,
  backgroundRepeat,
  backgroundColor = 'default',
  backgroundColorOverwrite,
  backgroundColorOpacity,
  backgroundStyle = 'default',
  ctas,
  fontColorOverride,
  locale,
  isPreview,
  isFirstBlock,
}: HeroFunnelProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });

  const opacity = getOpacity(backgroundColorOpacity!);
  const textSectionRef = useRef<HTMLDivElement>(null);
  const brand = useBrand();
  const [funnel, setFunnel] = useState<HTMLElement>();

  const backgroundImageUrl = (image?.src && image.src) || '';

  const textSection = (
    <div
      ref={textSectionRef}
      key="text"
      className={styles.textSection}
      {...inspectorProps({ fieldId: 'textContent' })}
    >
      <div className={styles.backgroundLayer}>
        <Image
          fill
          src={backgroundImageUrl}
          priority={isFirstBlock}
          alt=""
          style={{ objectFit: 'cover' }}
          className={clsx(
            backgroundStyle === 'blur' && backgroundStyleClass('blur'),
            backgroundRepeat && styles.backgroundRepeat,
          )}
          loader={createCloudimgLoader()}
        />
      </div>

      <div
        className={clsx(
          styles.backgroundLayer,
          backgroundStyle !== 'default' && [
            backgroundColorClass(backgroundColor),
            backgroundStyleClass('transparent'),
          ],
        )}
        style={{
          backgroundColor: backgroundColorOverwrite || undefined,
          opacity: opacity || 1,
        }}
      />

      <div
        className={clsx(
          styles.textContent,
          textColorClass(darkModeEnabled),
          centerContent && styles.centerContent,
        )}
      >
        <RichText
          fields={text}
          noWrapper
          style={
            {
              '--font-color': fontColorOverride,
            } as React.CSSProperties
          }
        />
        {!!ctas?.length && (
          <div
            className={clsx(styles.ctas, centerContent && styles.centerContent)}
          >
            {ctas.map((cta, i) => {
              const { funnel, product, action } = cta;
              const funnelUrn = funnel?.sys.urn;

              let funnelId = cta.funnelId || null;

              if (['funnel', 'calendar'].includes(action || '') && funnelUrn) {
                const index = funnelUrn.lastIndexOf('/');
                funnelId = funnelUrn.substring(index + 1);
              }

              const packageUrl = product?.sys.urn;
              let contentfulPackageId = null;

              if (action === 'calendar' && packageUrl) {
                const index = packageUrl.lastIndexOf('/');
                contentfulPackageId = packageUrl.substring(index + 1);
              }

              const contentfulPackage = contentfulPackageId
                ? { sys: { id: contentfulPackageId } }
                : undefined;

              return (
                <Cta
                  key={i}
                  {...cta}
                  contentfulPackage={contentfulPackage as Package}
                  funnelId={funnelId}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );

  const funnelSection = (
    <div key="funnel" className={styles.funnelContainer}>
      <div className={styles.loadingFunnel}>
        <Skeleton height={36} />
        <Skeleton height={26} width={180} />
        <Skeleton height={48} count={5} />
        <Skeleton height={26} />
      </div>

      <div className={styles.funnel}>
        {isPreview ? (
          <>
            {funnel ? <div ref={(node) => node?.appendChild(funnel)} /> : null}
            {funnelId && (
              <ExternalFunnelOnboardingPreview
                forceFullScreen
                beforeInteractive
                funnelId={funnelId}
                containerClasses={styles.funnel}
                funnelClass={styles.funnelContainerClasses}
                wrapperClass={styles.funnelWrapperClasses}
                onInteraction={hideConversionBarUntilScroll}
                useShadow
                configFromSameDomain
                customLogoOverride={brand?.logo}
                behavior="inline"
                locale={locale}
                setFunnel={setFunnel}
              />
            )}
          </>
        ) : (
          <>
            {funnelId && (
              <ExternalFunnel
                forceFullScreen
                beforeInteractive
                funnelId={funnelId}
                containerClasses={styles.funnel}
                funnelClass={styles.funnelContainerClasses}
                wrapperClass={styles.funnelWrapperClasses}
                onInteraction={hideConversionBarUntilScroll}
                useShadow
                configFromSameDomain
                customLogoOverride={brand?.logo}
                behavior="inline"
              />
            )}
          </>
        )}
      </div>
    </div>
  );

  const sections = [textSection, funnelSection];

  return (
    <div className={styles.container} {...inspectorProps({ fieldId: 'image' })}>
      <div className={styles.wrapper}>
        {!flipContent ? sections : sections.reverse()}
      </div>
    </div>
  );
};
